import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { StatusSystemIconType } from '@widgets/status-system-icon/status-system-icon.component';
import { VaylensSystemIconType } from '@manage/fleet-charging/data/fleet-charging.models';

export interface StatusCellData {
  type?: IconColor;
  outline?: boolean;
  text?: string;
  tooltip?: string;
  useNewStatusIcons?: boolean;
  statusSystemIcon?: StatusSystemIconType;
  vaylensIcon?: boolean;
  vaylensSystemIcon?: VaylensSystemIconType;
}

@Component({
  selector: 'status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
})
export class StatusCellComponent implements OnChanges {
  @Input()
  cellData: StatusCellData | StatusCellData[];

  multiple: boolean = false;
  statusCellData: StatusCellData[];

  readonly IconWeight = IconWeight;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.cellData) {
      if (Array.isArray(this.cellData)) {
        this.multiple = true;
        this.statusCellData = this.cellData;
      } else {
        this.statusCellData = [this.cellData];
      }
    }
  }
}
