<h3 class="headline">
  <div
    *ngIf="data.title"
    class="headline-icon-wrapper {{ data.titleIconColor ? data.titleIconColor : '' }}"
  >
    <eop-icon
      [color]="data.titleIconColor ? data.titleIconColor : IconColor.CLICKABLE"
      [identifier]="data.titleIconIdentifier ? data.titleIconIdentifier : 'info-circle-filled'"
      [size]="IconSize.LARGER"
      [vaylensFont]="true"
    ></eop-icon>
  </div>
  <div>{{ data.title | translate }}</div>
</h3>
<div class="message">
  <p [innerHTML]="data.message | translate"></p>
</div>
<div *ngIf="data.bulletPoints" class="info-box">
  <info-box [bulletPoints]="data.bulletPoints"></info-box>
</div>
<div *ngIf="data.tableConfig" class="info-box">
  <app-innogy-data-table
    [tableConfig]="data.tableConfig"
    [dataSource]="data.tableData"
  ></app-innogy-data-table>
</div>
<hr />
<div class="button-panel">
  <button (click)="onOk()" [attr.data-test]="'ok-button'" eopButton id="okButton">
    {{ 'GLOBAL.OK' | translate }}
  </button>
</div>
