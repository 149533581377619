import { Component, Input } from '@angular/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';

@Component({
  selector: 'eop-section-headline',
  templateUrl: './section-headline.component.html',
  styleUrls: ['./section-headline.component.scss'],
})
export class SectionHeadlineComponent {
  @Input()
  headlineIcon: string;

  @Input()
  tooltipText: string;

  @Input()
  rowDisplay: string = 'flex';

  @Input()
  iconFloat: string = 'none';

  @Input()
  vaylensFont = false;

  IconWeight = IconWeight;
  IconSize = IconSize;
  IconColor = IconColor;
}
