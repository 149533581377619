import { select } from '@angular-redux/store';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from '@auth/login';
import { TranslateService } from '@ngx-translate/core';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  GoogleAnalyticsLabel,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { TopNotificationService } from '@shared/services/notification';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SearchCategoryEnum, SearchResult } from '../data/quick-access.model';
import { QuickAccessService } from '../services/quick-access.service';
import { QuickSearchBaseComponent } from '@shared/components/header/quick-access/quick-search-base.component';

@Component({
  selector: 'eop-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent extends QuickSearchBaseComponent {
  @select(['router'])
  route$: Observable<string>;

  @select(['backendData', 'userLogin', 'settings', 'locale'])
  userLanguage$: Observable<Language>;

  searchPlaceholderText: string;

  constructor(
    protected quickAccessService: QuickAccessService,
    protected topNotificationService: TopNotificationService,
    protected cdr: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super(quickAccessService, topNotificationService, cdr);
  }

  private setInputPlaceholderText() {
    return this.translate
      .get(['GLOBAL.SEARCH_FOR', 'GLOBAL.CHARGING_STATION_ID', 'GLOBAL.CHARGE_POINT_ID'])
      .pipe(
        tap(translatedKeys => {
          this.searchPlaceholderText = `${translatedKeys['GLOBAL.SEARCH_FOR']} ${translatedKeys['GLOBAL.CHARGING_STATION_ID']}, ${translatedKeys['GLOBAL.CHARGE_POINT_ID']}`;
        })
      );
  }

  setPopoverVisibility(isVisible: boolean): void {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.QUICK_SEARCH,
      GoogleAnalyticsAction.COLLAPSE,
      isVisible ? GoogleAnalyticsLabel.OPEN : GoogleAnalyticsLabel.CLOSE
    );
    this.popoverVisible = isVisible;
    this.popoverVisible$.emit(this.popoverVisible);
  }

  selected(element: SearchResult) {
    this.navigate(element);
  }

  navigate(element: SearchResult): void {
    this.setPopoverVisibility(false);
    this.searchInput.nativeElement.blur();

    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.QUICK_SEARCH,
      GoogleAnalyticsAction.SEARCH,
      element.category === SearchCategoryEnum.CHARGINGSTATION
        ? GoogleAnalyticsLabel.CHARGING_STATION
        : GoogleAnalyticsLabel.CHARGE_PORT
    );

    this.router.navigate(['/operate/chargingInfrastructureDetail'], {
      queryParams: {
        uuid: element.stationUuid,
        ...(element.category === SearchCategoryEnum.CHARGEPOINT && {
          chargePort: element.value,
        }),
        ...(element.category === SearchCategoryEnum.CHARGINGSTATION && {
          chargingStation: element.value,
        }),
      },
    });
  }

  protected resetSearchInputOnRouteChange(): void {
    this.route$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.searchInput.nativeElement.value = '';
      this.minCharsReached = false;
    });
  }

  protected handleLanguageChange(): void {
    this.userLanguage$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.setInputPlaceholderText())
      )
      .subscribe();
  }
}
