import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TopNotificationService } from '@shared/services/notification';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { QuickAccessService } from './services/quick-access.service';
import { QuickSearchResult, SearchResult } from './data/quick-access.model';

export enum KeyboardKeys {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  ENTER = 'Enter',
}

@Directive()
export abstract class QuickSearchBaseComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  @Output() popoverVisible$ = new EventEmitter();
  popoverVisible: boolean = false;

  searchTerms$ = new Subject<string>();

  numOfMinCharsToStartSearch: number = 4;
  searchInProgress: boolean;

  minCharsReached = false;
  currentResults: QuickSearchResult;
  selectedResult: SearchResult;

  inititalResults: QuickSearchResult = {
    chargepointResults: 0,
    chargingStationResults: 0,
    searchResults: [],
  };

  protected constructor(
    protected quickAccessService: QuickAccessService,
    protected topNotificationService: TopNotificationService,
    protected cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.resetSearchInputOnRouteChange();
    this.handleLanguageChange();

    this.searchTerms$
      .pipe(
        distinctUntilChanged(),
        tap(() => (this.searchInProgress = true)),
        debounceTime(500),
        switchMap(term => {
          return term && this.minCharsReached
            ? this.quickAccessService.quickSearch(term).pipe(
                catchError(error => {
                  this.searchInProgress = false;
                  this.topNotificationService.showErrorInTopNotification(error);
                  return of<QuickSearchResult>(this.inititalResults);
                })
              )
            : of<QuickSearchResult>(this.inititalResults);
        }),
        startWith(this.inititalResults),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((results: QuickSearchResult) => {
        this.currentResults = results;
        if (results.searchResults.length === 1) {
          this.selectedResult = this.currentResults.searchResults[0];
        }
        this.searchInProgress = false;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  abstract selected(element: SearchResult): void;

  // only for header menu
  protected abstract resetSearchInputOnRouteChange(): void;

  // only for header menu
  protected abstract handleLanguageChange(): void;

  onKeyUpWithinInput(event: KeyboardEvent): void {
    event.stopImmediatePropagation();

    if (this.popoverVisible && this.minCharsReached) {
      this.handleArrowNavigation(event);
    }

    const searchTerm = this.searchInput.nativeElement.value.trim();
    this.minCharsReached = searchTerm.length >= this.numOfMinCharsToStartSearch;
    this.searchTerms$.next(searchTerm);
  }

  setPopoverVisibility(isVisible: boolean): void {
    this.popoverVisible = isVisible;
    this.popoverVisible$.emit(this.popoverVisible);
  }

  onKeyDownWithinInput(event: KeyboardEvent): void {
    if (event.key === KeyboardKeys.DOWN || event.key === KeyboardKeys.UP) {
      event.preventDefault();
    }
  }

  private handleArrowNavigation(event: KeyboardEvent): void {
    let index = this.selectedResult
      ? this.currentResults.searchResults.findIndex(elm => elm.value === this.selectedResult.value)
      : -1;
    if (event.key === KeyboardKeys.DOWN) {
      index = this.mod(index + 1, this.currentResults.searchResults.length);
      this.selectedResult = this.currentResults.searchResults[index];
      return;
    }
    if (event.key === KeyboardKeys.UP) {
      index = this.mod(index - 1, this.currentResults.searchResults.length);
      this.selectedResult = this.currentResults.searchResults[index];
      return;
    }
    if (event.key === KeyboardKeys.ENTER && this.selectedResult) {
      this.selected(this.selectedResult);
      return;
    }
  }

  /**
   * This is needed because default javascript '%' operator
   * cannot handle negative values as expected here.
   *
   * e.g. -1 % 3 returns -1 instead of 2
   */
  private mod(a: number, b: number): number {
    return ((a % b) + b) % b;
  }
}
