import { NgRedux, select } from '@angular-redux/store';
import { registerLocaleData } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IUserLogin, Language } from '@auth/login';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { AppVersionService } from '@shared/services/app-version/app-version.service';
import { StorageBroadcastService } from '@shared/services/auth';
import { NavigationService } from '@shared/services/navigation/navigation-service.service';
import { TopNotificationService } from '@shared/services/notification';
import { INotification } from '@shared/store/data/session-data.model';
import { LanguageService } from '@shared/translate/language.service';
import { ModalService } from '@widgets/modal/modal.service';
import { environment } from 'environments/environment';
import { setAutoFreeze } from 'immer';
import { isEmpty } from 'lodash-es';
import md5 from 'md5';
import moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { polyfill } from 'smoothscroll-polyfill';
import userflow, { Attributes } from 'userflow.js';
import { IAppState } from './shared/store/models';

enum TagManagerCookieLevel {
  REQUIRED = '1',
  ALL = '2',
}

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  readonly DEFAULT_TAB_TITLE = 'vaylens';

  @select(['sessionData', 'notification'])
  notification$: Observable<INotification>;

  @select(['sessionData', 'sidebarCollapsed'])
  sidebarCollapsed$: Observable<boolean>;

  @select(['sessionData', 'rehydrationCompleted'])
  private rehydrationCompleted$: Observable<boolean>;

  languagesLoaded: boolean;
  sidebarCollapsed: boolean;

  private unsubscribe$ = new Subject<void>();
  private userFlowActivated = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: NgRedux<IAppState>,
    private storageBroadcastService: StorageBroadcastService,
    private topNotificationService: TopNotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private appVersionService: AppVersionService,
    private languageService: LanguageService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private navigationService: NavigationService // This import is necessary
  ) {}

  ngOnInit() {
    this.rehydrationCompleted$
      .pipe(
        filter((rehydrationCompleted: boolean) => rehydrationCompleted === true),
        first()
      )
      .subscribe((rehydration: boolean) => {
        this.storageBroadcastService.start();
        this.storageBroadcastService.requestForUserFilterOptions();
        this.checkUserLogin();
      });

    this.translate.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => (this.languagesLoaded = true));
    this.disableDevFeatures();

    polyfill();

    akitaConfig({
      resettable: true,
    });

    this.googleAnalyticsService.listenToRouteChanges();
    this.appVersionService.listenToRouteChanges();

    this.closeModalsOnBackNavigation();
    this.setTabTitle();

    this.sidebarCollapsed$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.sidebarCollapsed = value;
      this.cdr.detectChanges();
    });
  }

  closeNotification() {
    this.topNotificationService.closeTopNotificiation();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isPageWithoutMenu(): boolean {
    const userLogin = this.store.getState().backendData.userLogin;
    const pages = [environment.legalNoticeUrlEN];
    return (
      !(userLogin && userLogin.userId) &&
      (this.inPageList(pages) || this.inPageRegardlessParameters('/content/userManualPublic'))
    );
  }

  isAnonymousUserPages(): boolean {
    const pages = ['/', '/auth/login', '/auth/support'];
    return this.inPageList(pages) || this.inPageRegardlessParameters('/auth/passwordReset');
  }

  isFullWidthPage(): boolean {
    const page = '/monitor/chargingInfrastructure';
    return this.inPageRegardlessParameters(page);
  }

  inPage(page: string): boolean {
    return this.router.url === page;
  }

  inPageList(pages: Array<string>) {
    let inList = false;
    pages.forEach(page => {
      if (this.inPage(page)) {
        inList = true;
      }
    });
    return inList;
  }

  inPageRegardlessParameters(page: string): boolean {
    return this.router.url.indexOf(page) !== -1;
  }

  private checkUserLogin() {
    const userFromSessionStorage$ = this.store.select(['backendData', 'userLogin']);
    const settingValueList$ = this.store.select(['backendData', 'settingValueList']);

    combineLatest([userFromSessionStorage$, settingValueList$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([userLogin, settings]) => {
        if (isEmpty(userLogin)) {
          this.userFlowActivated = false;
        }
        if (!isEmpty(userLogin) && isEmpty(settings)) {
          return;
        }
        this.setTranslationLanguage();
        const userData = userLogin as IUserLogin;
        const tagManagerCookieLevel: TagManagerCookieLevel = this.getGoogleTagManagerCookieLevel();
        if (
          !this.userFlowActivated &&
          (environment.userFlowWorkspaceToken || userData?.userId?.includes('cypress')) &&
          userData?.userId &&
          tagManagerCookieLevel
        ) {
          this.initUserFlow(userData, tagManagerCookieLevel);
          this.userFlowActivated = true;
        }
      });
  }

  private initUserFlow(userData: IUserLogin, tagManagerCookieLevel: TagManagerCookieLevel) {
    const identifyObj = this.getUserForUserflowIdentify(userData, tagManagerCookieLevel);
    console.debug('Userflow mail: ' + identifyObj.email);
    console.debug('Userflow name: ' + identifyObj.name);
    if (identifyObj) {
      userflow.init(environment.userFlowWorkspaceToken.toString());
      userflow.identify(identifyObj.name as string, identifyObj);
      if (!userData.internalRole) {
        // This is needed for setting a company for the user
        userflow.group(userData.visualStyleId);
      }
    }
  }

  private getUserForUserflowIdentify(
    userData: IUserLogin,
    tagManagerCookieLevel: TagManagerCookieLevel
  ): Attributes {
    const emailDomain: string = `@${userData.userId.split('@')[1]}`;
    if (
      emailDomain.includes('@compleo-cs.com') ||
      emailDomain.includes('@vaylens.com') ||
      emailDomain.includes('.vaylens.com')
    ) {
      return {
        name: this.getHashedUser(userData.userId),
        email: `non${emailDomain}`,
        signed_up_at: moment().utc().format().toString(),
        locale_code: userData.settings.locale?.toLocaleUpperCase(),
        isInternalUser: userData.internalRole,
        businessPartnerId: userData.internalRole ? null : userData.visualStyleId,
      };
    }

    switch (tagManagerCookieLevel) {
      case TagManagerCookieLevel.REQUIRED:
        return {
          name: this.getHashedUser(userData.userId),
          email: `non${emailDomain}`,
          signed_up_at: moment().utc().format().toString(),
          locale_code: userData.settings.locale?.toLocaleUpperCase(),
          isInternalUser: userData.internalRole,
          businessPartnerId: userData.internalRole ? null : userData.visualStyleId,
        };
      case TagManagerCookieLevel.ALL:
        return {
          name: `${userData.firstName} ${userData.lastName}`,
          email: `${userData.userId}`,
          signed_up_at: moment().utc().format().toString(),
          locale_code: userData.settings.locale?.toLocaleUpperCase(),
          isInternalUser: userData.internalRole,
          businessPartnerId: userData.internalRole ? null : userData.visualStyleId,
        };
      default:
        return;
    }
  }

  private getHashedUser(userId: string): string {
    return md5(userId);
  }

  private getGoogleTagManagerCookieLevel(): TagManagerCookieLevel {
    return document.cookie
      .split(';')
      .find(c => c.includes('dws01-level'))
      ?.split('=')[1] as TagManagerCookieLevel;
  }

  private setTranslationLanguage() {
    const language = this.languageService.getUserLanguage();
    if (language !== Language.Empty) {
      this.localeInitializer(language);
    }
    this.translate.use(language);
  }

  private localeInitializer(language: Language) {
    import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-extra" */
      /* webpackInclude: /(de|en|da|es|fr|hu|it|nl|sv)\.mjs$/ */
      `/node_modules/@angular/common/locales/${language.toString().substring(0, 2)}`
    ).then(module => {
      registerLocaleData(module.default);
    });
  }

  private disableDevFeatures() {
    if (!isDevMode()) {
      // Disable auto-freeze feature of the immer library to improve performance
      setAutoFreeze(false);
      enableAkitaProdMode();
    }
  }

  private closeModalsOnBackNavigation() {
    this.router.events
      .pipe(
        filter((event: NavigationStart) => event.navigationTrigger === 'popstate'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.modalService.closeAllModals());
  }

  private setTabTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['tabTitleKey']) {
            routeTitle = route!.snapshot.data['tabTitleKey'];
          }
          return routeTitle;
        }),
        switchMap(titleKey =>
          titleKey ? this.translate.stream(titleKey) : of(this.DEFAULT_TAB_TITLE)
        )
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
  }
}
