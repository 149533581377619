import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IconColor } from '@widgets/eop-icon';
import { ComponentData } from '../../services/filter-frontend.model';
import { OverlayService } from '../../services/overlay.service';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { SingleselectComponent } from '../singleselect/singleselect.component';
import { FilterContainerConfiguration } from '../../filter-container.component';

@Component({
  selector: 'eop-add-filter-button',
  templateUrl: './add-filter-button.component.html',
  styleUrls: ['./add-filter-button.component.scss'],
})
export class AddFilterButtonComponent {
  @ViewChild('origin', { static: true })
  origin: ElementRef;

  @Input()
  availableSpecificFilters: FilterContainerConfiguration[];

  @Output()
  addSpecificFilter = new EventEmitter<FilterContainerConfiguration>();

  readonly ButtonColor = ButtonColor;
  readonly IconColor = IconColor;

  open = false;

  constructor(
    private overlaySvc: OverlayService,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  toggleOpen() {
    this.open = !this.open;

    if (this.open) {
      this.show(this.origin);
    }
  }

  private show(origin: ElementRef) {
    const popoverRef = this.overlaySvc.open({
      content: SingleselectComponent,
      origin,
      width: 400,
      height: 500,
      data: {
        items: this.availableSpecificFilters
          .filter(f => f.mandatory != true)
          .map(f => ({ id: f.title, name: f.title })),
      },
      vcr: this.vcr,
      callback: this.callback.bind(this),
    });
    popoverRef.afterClosed$.subscribe(res => {
      this.open = false;
      this.overlaySvc.closeAllOverlays();
      this.cdr.detectChanges();
    });
  }

  public callback(outputData: ComponentData) {
    const selectedFilter = outputData.items.find(f => f.selected);
    this.addSpecificFilter.emit(
      this.availableSpecificFilters.find(f => f.title == selectedFilter.id)
    );
  }
}
