<div *ngIf="!statusCellData">-</div>
<ng-container *ngFor="let cell of statusCellData; let isFirst = first">
  <div
    *ngIf="isFirst && multiple"
    class="minus"
    [style.width.px]="25 * (statusCellData.length - 1)"
  ></div>
  <div class="line" *ngIf="cell.vaylensIcon" [matTooltip]="cell.tooltip">
    <eop-icon
      *ngIf="!cell.statusSystemIcon && !cell.useNewStatusIcons"
      [identifier]="cell.vaylensSystemIcon.identifier"
      [color]="cell.vaylensSystemIcon.color"
      [vaylensFont]="true"
    ></eop-icon>
    <div class="text">{{ cell.text }}</div>
  </div>

  <div class="line" *ngIf="!cell.vaylensIcon">
    <div class="bubble" [matTooltip]="cell.tooltip">
      <eop-status-system-icon
        *ngIf="cell.useNewStatusIcons && cell.statusSystemIcon && !cell.vaylensIcon"
        [type]="cell.statusSystemIcon"
      ></eop-status-system-icon>
      <eop-icon
        *ngIf="cell.type != null && !cell.useNewStatusIcons && !cell.vaylensIcon"
        [identifier]="cell.outline ? 'Status-outline' : 'Status'"
        [color]="cell.type"
        [weight]="IconWeight.BOLD"
      ></eop-icon>
    </div>
    <div *ngIf="!multiple" class="text" [matTooltip]="cell.text">{{ cell.text }}</div>
  </div>
</ng-container>
