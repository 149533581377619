const BASE_URL = 'https://api-qa.vaylens.com';

// CMM
const CMM_BASE_URL = 'https://branding.services-emobility.com';
const CMM_API_URL = 'cmmTest/gw/cmm/api/v1';

// CAA
const BACKEND_URL_LOGIN_LOGOUT = 'portal/api/auth-rest/v2';
const BACKEND_URL_CAA = 'portal/caa/api/v3';
const BACKEND_URL_CAA_PUBLIC = 'portal/caa/public/api/v3';

// FUTURE ICON BFFs
const BFF_INNOGY_ADMIN = 'portal/innogy-admin/api';
const BFF_CHARGING_STATION_OPERATIONS = 'portal/charging-station-operations/api';
const BFF_CHARGING_AUTHORIZATION = 'portal/charging-authorization/api/v3';
const BFF_REPORTING = 'portal/reports/api';
const BFF_PRICING = 'portal/pricing/api';
const BFF_API_MANAGEMENT = 'portal/api-management/api';
const BFF_CHARGING_INFRASTRUCTURE_OPERATIONS = 'portal/charging-infrastructure-operations/api/v4';
const BFF_SESSION_LIFECYCLE_OVERVIEW = 'portal/session-lifecycle/api';
const BFF_CLIENT_ACCOUNT_MANAGEMENT = 'portal/client-account-management/api/v2';
const BFF_EOP_SEARCH = 'portal/eop-search/api/v1';
const BFF_USER_CHARGING = 'portal/user-charging/api/v1';
const BFF_DRIVER_MANAGEMENT = 'portal/driver-management/api/v1';

// ITEM-GATEWAY
const ITEM_GATEWAY_BASE_URL = 'https://apiqa.services-emobility.com';

export const environment = {
  production: false,
  int: false,
  baseUrl: BASE_URL,
  backendUrlLoginAndLogout: `${BASE_URL}/${BACKEND_URL_LOGIN_LOGOUT}`,
  backendUrlReporting: `${BASE_URL}/${BFF_REPORTING}`,
  backendUrlCAA: `${BASE_URL}/${BACKEND_URL_CAA}`,
  backendUrlCAAPublic: `${BASE_URL}/${BACKEND_URL_CAA_PUBLIC}`,
  backendUrlApiMangement: `${BASE_URL}/${BFF_API_MANAGEMENT}`,
  cmmBaseUrl: `${CMM_BASE_URL}`,
  cmmUrl: `${CMM_BASE_URL}/${CMM_API_URL}`,
  bffInnogyAdmin: `${BASE_URL}/${BFF_INNOGY_ADMIN}`,
  bffChargingStationOperations: `${BASE_URL}/${BFF_CHARGING_STATION_OPERATIONS}`,
  bffChargingAuthorization: `${BASE_URL}/${BFF_CHARGING_AUTHORIZATION}`,
  bffPricing: `${BASE_URL}/${BFF_PRICING}`,
  bffChargingInfrastructureOperations: `${BASE_URL}/${BFF_CHARGING_INFRASTRUCTURE_OPERATIONS}`,
  bffSessionLifecycleOverview: `${BASE_URL}/${BFF_SESSION_LIFECYCLE_OVERVIEW}`,
  bffClientAccountManagement: `${BASE_URL}/${BFF_CLIENT_ACCOUNT_MANAGEMENT}`,
  bffEopSearch: `${BASE_URL}/${BFF_EOP_SEARCH}`,
  bffUserCharging: `${BASE_URL}/${BFF_USER_CHARGING}`,
  bffDriverManagement: `${BASE_URL}/${BFF_DRIVER_MANAGEMENT}`,
  checkBillDownloadUrl:
    'https://www.compleo-charging.com/fileadmin/Documentcenter/Eichrecht_Checkbill/INT_eSTATIONsmart_checkbill-2-3-0-9.jar',
  tranzparenzSoftwareDownloadUrl: `https://www.safe-ev.de/`,
  googleTagManager: 'GTM-WXCQDM8',
  supportMail: 'help@emobility.software',
  // Copyright data protection
  dataProtectionUrlEN: 'https://vaylens.com/privacy-policy',
  dataProtectionUrlDE: 'https://vaylens.com/de/datenschutz',
  legalNoticeUrlEN: 'https://vaylens.com/legal-notice',
  legalNoticeUrlDE: 'https://vaylens.com/de/impressum',
  ideaBoard: 'https://ideaboard.vaylens.com/',
  userFlowWorkspaceToken: 'ct_q5ym6fb235guzlafv4pbc4vini',
  userFlowFeedbackActionId: '01e01e60-adf8-496f-897e-43b5bcd906dd',
  userFlowIbetFeedbackActionId: 'df71877d-f180-4fe5-89ba-b2d88c25820f',
  userFlowVaylensPayFeedbackActionId: '411321be-575b-4e5c-884c-32752ed6e77c',
  userFlowFeedbackExtendedOverviewActionId: '2bc774d5-08b1-4b66-bea0-b3e73254d486',
  stationBackendUrlEndpointInternet: 'wss://qa.services-emobility.com/device/ocpp16',
  stationBackendUrlEndpointVpn: 'ws://10.185.70.60:2302/ocpp16/ocppj16',
  stationServiceUrl: 'https://help.vaylens.com/protocols-for-lg2wan-stations',
  calibrationLawCheckService: ITEM_GATEWAY_BASE_URL + '/vm/service/VirtualMeterServlet',
  connectivityTesterUrl: 'https://connectiontest-qa.services-emobility.com/',
  googleMapsApiKey: 'AIzaSyDx18xuwiRqaB4A_oe5eSYEhNpie5Hvbqs',
  googleMapId: '768c344fd4b3ff6c',
  homeChargerAppUrl: 'https://homecharger-qa.vaylens.com/',
  communityChargingDocUrl:
    'https://help.vaylens.com/community-charging-as-cpo#CC-affect-existing-setup',
  // Feature toggles
  standaloneTerminalFeatureActive: true,
  xPayTerminalFeatureActive: true,
  quicksightFeatureActive: true,
  voucherFeatureActive: true,
  //it should be false for PROD
  vaylensPayPreAuthAmountFeatureActive: true,
};
