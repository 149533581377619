import { NgRedux, select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { isBrowserInGerman } from '@shared/components/header/menu-footer/menu-footer.component';
import { BackendDataActions, SessionDataActions } from '@shared/store/actions';
import { EWaitResponseType } from '@shared/store/data/session-data.model';
import { IAppState } from '@shared/store/models';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor } from '@widgets/eop-icon';
import { ProgressStates } from '@widgets/save-button';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  protected readonly ButtonColor = ButtonColor;

  @select(['sessionData', 'waitLogin'])
  waitLogin$: Observable<EWaitResponseType>;

  username: string;
  password: string;
  progressState: ProgressStates;
  subscription: Subscription;
  IconColor = IconColor;
  showPassword = false;
  dataProtectionUrl: string;
  legalNoticeUrl: string;

  constructor(
    private backendDataActions: BackendDataActions,
    private store: NgRedux<IAppState>,
    private router: Router,
    private sessionDataActions: SessionDataActions,
    private urlSerializer: UrlSerializer
  ) {}

  ngOnInit(): void {
    this.progressState = ProgressStates.Begin;

    if (this.store.getState().sessionData.waitLogin === EWaitResponseType.WAITING) {
      this.progressState = ProgressStates.Sent;
    }

    const useGermanUrls = isBrowserInGerman();

    this.dataProtectionUrl = useGermanUrls
      ? environment.dataProtectionUrlDE
      : environment.dataProtectionUrlEN;

    this.legalNoticeUrl = useGermanUrls
      ? environment.legalNoticeUrlDE
      : environment.legalNoticeUrlEN;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  login() {
    this.backendDataActions.startLogin(this.username.trim(), this.password);

    this.subscription = this.waitLogin$.subscribe(data => {
      if (data === EWaitResponseType.FINISH || data === EWaitResponseType.FINISH_WITH_ERROR) {
        this.progressState = ProgressStates.Begin;
        this.navigateToSavedPage();
      } else {
        this.progressState = ProgressStates.Sent;
      }
    });
  }

  private navigateToSavedPage(): void {
    const unauthNavigatedPage = this.store.getState().sessionData.unauthNavigatedPage;
    this.sessionDataActions.saveUnauthNavigatedPage('');

    if (isEmpty(unauthNavigatedPage)) {
      this.router.navigate(['/monitor/dashboard']);
    } else {
      const urlParsed = this.urlSerializer.parse(unauthNavigatedPage);
      const urlFragment = unauthNavigatedPage.split('?')[0];
      this.router.navigate([urlFragment], {
        queryParams: urlParsed.queryParams,
      });
    }
  }
}
