import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { WidgetModule } from '@widgets/widgets.module';
import { QuickAccessEntryTitleComponent } from './components/quick-access-entry-title/quick-access-entry-title.component';
import { QuickAccessEntryComponent } from './components/quick-access-entry/quick-access-entry.component';
import { QuickAccessResultsComponent } from './components/quick-access-results/quick-access-results.component';
import { QuickAccessComponent } from './quick-access/quick-access.component';

@NgModule({
  declarations: [
    QuickAccessComponent,
    QuickAccessResultsComponent,
    QuickAccessEntryComponent,
    QuickAccessEntryTitleComponent,
  ],
  imports: [CommonModule, WidgetModule, TranslateModule, SharedDirectivesModule, MatTooltipModule],
  exports: [QuickAccessComponent, QuickAccessResultsComponent],
})
export class QuickAccessModule {}
