<!--  This is the lite version of the chips component, keep it so bro-->
<div
  class="chips-lite"
  [ngClass]="chipColor"
  [class.chips-lite--medium]="chipSize === ChipLiteSize.MEDIUM"
  [class.chips-lite--big]="chipSize === ChipLiteSize.BIG"
  [class.is-emphasized]="emphasized"
  [style.max-width]="maxWidth ? maxWidth : 'unset'"
  [matTooltip]="title"
  [attr.data-test]="testId"
>
  <eop-icon
    *ngIf="leftIcon"
    class="icon"
    [class.icon--medium]="chipSize === ChipLiteSize.MEDIUM"
    [class.icon--big]="chipSize === ChipLiteSize.BIG"
    [identifier]="leftIcon"
    [vaylensFont]="true"
    [clickable]="true"
    [color]="iconColor ? iconColor : emphasized ? IconColor.WHITE : IconColor.NORMAL"
    [size]="IconSize.MINI"
    (click)="onLeftIconClick()"
    dataTestId="{{ testId }}-prefix"
  ></eop-icon>
  <span
    [class.body-bold-s]="!chipSize || chipSize === ChipLiteSize.SMALL"
    [class.body-bold-m]="chipSize === ChipLiteSize.MEDIUM"
    [class.body-bold-l]="chipSize === ChipLiteSize.BIG"
    [style.margin-left]="!leftIcon ? '4px' : ''"
    [style.margin-right]="!rightIcon ? '4px' : ''"
    [matTooltip]="enableTitleTooltip ? text : undefined"
  >
    {{ text }}
  </span>
  <eop-icon
    *ngIf="rightIcon"
    class="icon"
    [class.icon--medium]="chipSize === ChipLiteSize.MEDIUM"
    [class.icon--big]="chipSize === ChipLiteSize.BIG"
    [identifier]="rightIcon"
    [vaylensFont]="true"
    [clickable]="true"
    [color]="iconColor ? iconColor : emphasized ? IconColor.WHITE : IconColor.NORMAL"
    (click)="onRightIconClick()"
    dataTestId="{{ testId }}-suffix"
  ></eop-icon>
</div>
