import { Injectable } from '@angular/core';
import { ActivationEntry } from '@monitor/activation-overview/data/activation-overview.model';
import { TranslateService } from '@ngx-translate/core';
import { IChargePointStatus } from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { IconColor } from '@widgets/eop-icon';
import { StatusCellData } from '@widgets/innogy-data-table';
import moment from 'moment';

export enum EStatusEnum {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  UNKNOWN = 'UNKNOWN',
  NOT_OPERATIONAL = 'NOT_OPERATIONAL',
}

export enum EDetailedStatusEnum {
  OCCUPIED_RESERVED = 'OCCUPIED_RESERVED',
  OCCUPIED_STARTING = 'OCCUPIED_STARTING',
  OCCUPIED_CHARGING = 'OCCUPIED_CHARGING',
  OCCUPIED_SUSPENDED = 'OCCUPIED_SUSPENDED',
  OCCUPIED_FINISHING = 'OCCUPIED_FINISHING',
  OUT_OF_SERVICE_ERROR = 'OUT_OF_SERVICE_ERROR',
  OUT_OF_SERVICE_INOPERATIVE = 'OUT_OF_SERVICE_INOPERATIVE',
  UNKNOWN_NO_HEARTBEAT = 'UNKNOWN_NO_HEARTBEAT',
  UNKNOWN_NEW = 'UNKNOWN_NEW',
  UNKNOWN_INVALID_RESPONSE = 'UNKNOWN_INVALID_RESPONSE',
  UNKNOWN_UNAVAILABLE = 'UNKNOWN_UNAVAILABLE',
  UNKNOWN_NOT_RELEVANT = 'UNKNOWN_NOT_RELEVANT',
  NONE = 'NONE',
}

export interface IActivationEntry {
  id: string;
  startTime: string;
  stopTime: string;
  chargepointID: string;
  authType: string;
  authToken: string;
  activationState: string;
  bdrID: number;
  charging: boolean;
  contractID: string;
  sessionState: string;
  status: ChargingSessionStatus;
  errorMessage: string;
  statusDetail: ChargingSessionDetailedStatus;
}

export enum ChargingSessionDetailedStatus {
  // For Charging status:
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  // For Ended status:
  SUCCEEDED = 'SUCCEEDED',
  ABORTED = 'ABORTED',
  SDR_INVALID = 'SDR_INVALID',
  PERISHED = 'PERISHED',
  // For Error status:
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  CHARGING_FAILED = 'CHARGING_FAILED',
  STOP_REQUEST_FAILED = 'STOP_REQUEST_FAILED',
  AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED',
  START_REQUEST_FAILED = 'START_REQUEST_FAILED',
  STOP_ACTIVATION_FAILED = 'STOP_ACTIVATION_FAILED',
  START_ACTIVATION_FAILED = 'START_ACTIVATION_FAILED',
}

export enum ChargingSessionStatus {
  ERROR = 'ERROR',
  ENDED = 'ENDED',
  CLOSED = 'CLOSED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  CHARGING = 'CHARGING',
  STOP_REQUESTED = 'STOP_REQUESTED',
  START_REQUESTED = 'START_REQUESTED',
  STOP_AUTHORIZED = 'STOP_AUTHORIZED',
  START_AUTHORIZED = 'START_AUTHORIZED',
  AUTHORIZATION_PENDING = 'AUTHORIZATION_PENDING',
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private translate: TranslateService) {}

  convertSessionStatusIntoStatusCellData(
    row: ActivationEntry | IActivationEntry
  ): StatusCellData[] {
    const allStatuses = [
      this.translate.instant('UNLOCK_MONITOR.AUTHORIZATION'),
      this.translate.instant('UNLOCK_MONITOR.ACTIVATION'),
      this.translate.instant('UNLOCK_MONITOR.CHARGING'),
      this.translate.instant('UNLOCK_MONITOR.DEACTIVATION'),
      this.translate.instant('UNLOCK_MONITOR.BDR_CREATION'),
    ];
    const successStatuses = allStatuses.map(name => ({ type: IconColor.SUCCESS, tooltip: name }));
    const emptyStatuses = allStatuses.map(name => ({ type: IconColor.WHITE, tooltip: name }));

    let statusProgress: StatusCellData[] = [];
    if (row.status === ChargingSessionStatus.ERROR) {
      let errorPosition;
      switch (row.statusDetail) {
        case ChargingSessionDetailedStatus.AUTHORIZATION_FAILED:
          errorPosition = 0;
          break;
        case ChargingSessionDetailedStatus.START_REQUEST_FAILED:
        case ChargingSessionDetailedStatus.START_ACTIVATION_FAILED:
          errorPosition = 1;
          break;
        case ChargingSessionDetailedStatus.CHARGING_FAILED:
          errorPosition = 2;
          break;
        case ChargingSessionDetailedStatus.STOP_REQUEST_FAILED:
        case ChargingSessionDetailedStatus.STOP_ACTIVATION_FAILED:
          errorPosition = 3;
          break;
      }
      if (errorPosition !== undefined) {
        statusProgress = successStatuses.slice(0, errorPosition);
        statusProgress.push({ type: IconColor.ERROR, tooltip: allStatuses[errorPosition] });
      }
    } else {
      let position;
      let color = IconColor.SUCCESS;
      let fillAll = false;
      switch (row.status) {
        case ChargingSessionStatus.AUTHORIZATION_PENDING:
          position = 0;
          color = IconColor.DISABLED;
          break;
        case ChargingSessionStatus.START_REQUESTED:
        case ChargingSessionStatus.START_AUTHORIZED:
          position = 0;
          break;
        case ChargingSessionStatus.STARTED:
          position = 1;
          break;
        case ChargingSessionStatus.CHARGING:
          {
            switch (row.statusDetail) {
              case ChargingSessionDetailedStatus.ACTIVE:
              case ChargingSessionDetailedStatus.SUSPENDED:
                position = 2;
                color = IconColor.INFO;
                break;
            }
          }
          break;
        case ChargingSessionStatus.STOP_AUTHORIZED:
          position = 2;
          color = IconColor.INFO;
          break;
        case ChargingSessionStatus.STOP_REQUESTED:
          position = 2;
          break;
        case ChargingSessionStatus.STOPPED:
          position = 3;
          break;
        case ChargingSessionStatus.ENDED:
          {
            color = IconColor.DISABLED;
            switch (row.statusDetail) {
              case ChargingSessionDetailedStatus.SUCCEEDED:
                position = 4;
                break;
              case ChargingSessionDetailedStatus.ABORTED:
                fillAll = true;
                position = 2;
                break;
              case ChargingSessionDetailedStatus.SDR_INVALID:
                color = IconColor.ERROR;
                position = 4;
                break;
              case ChargingSessionDetailedStatus.PERISHED:
                fillAll = true;
                position = 0;
                break;
            }
          }
          break;
        case ChargingSessionStatus.CLOSED:
          position = 4;
          color = row.bdrID === null ? IconColor.DISABLED : IconColor.SUCCESS;
          break;
      }
      if (position !== undefined) {
        statusProgress = successStatuses.slice(0, position);
        do {
          statusProgress.push({ type: color, tooltip: allStatuses[position] });
        } while (fillAll && ++position < 5);

        const endedTime = moment(row.stopTime).utc();
        const oneDay = moment().utc().subtract(1, 'day');
        if (
          row.status === ChargingSessionStatus.ENDED &&
          row.statusDetail === ChargingSessionDetailedStatus.SUCCEEDED &&
          endedTime.isBefore(oneDay)
        )
          statusProgress[4] = { type: IconColor.ERROR, tooltip: allStatuses[4] };
      }
    }

    return statusProgress.concat(emptyStatuses.slice(statusProgress.length));
  }

  convertSessionDetailedStatusIntoStatusCellData(
    row: ActivationEntry | IActivationEntry,
    displayFullErrorStatus: boolean = false,
    customerFriendly: boolean = false
  ): StatusCellData {
    let detailedStatus: StatusCellData;
    if (row.status === ChargingSessionStatus.ERROR) {
      const rootCauseMessage = row.errorMessage
        ? row.errorMessage
        : this.translate.instant('UNLOCK_MONITOR.NO_DETAILED_ERROR');
      const tooltip =
        (row.statusDetail
          ? this.translate.instant('UNLOCK_MONITOR.' + row.statusDetail)
          : 'UNKNOWN_ERROR') +
        ' - ' +
        rootCauseMessage;
      detailedStatus = {
        type: IconColor.ERROR,
        tooltip: tooltip,
        text: customerFriendly
          ? this.translate.instant(
              'UNLOCK_MONITOR.' + (row.statusDetail ? row.statusDetail : 'UNKNOWN_ERROR')
            )
          : displayFullErrorStatus
          ? tooltip
          : rootCauseMessage,
      };
    } else {
      let iconColor;
      let tooltip: string = row.status;
      switch (row.status) {
        case ChargingSessionStatus.CHARGING:
          iconColor = IconColor.INFO;
          tooltip = row.status + (row.statusDetail ? '_' + row.statusDetail : '');
          break;
        case ChargingSessionStatus.STOP_AUTHORIZED:
          iconColor = IconColor.INFO;
          break;
        case ChargingSessionStatus.ENDED:
          iconColor = IconColor.DISABLED;
          tooltip = row.status + (row.statusDetail ? '_' + row.statusDetail : '');
          break;
        case ChargingSessionStatus.AUTHORIZATION_PENDING:
        case ChargingSessionStatus.CLOSED:
          iconColor = IconColor.DISABLED;
          break;
        default:
          iconColor = IconColor.SUCCESS;
      }
      tooltip = this.translate.instant('UNLOCK_MONITOR.' + tooltip);
      detailedStatus = {
        type: iconColor,
        text: tooltip,
        tooltip,
      };
    }
    return !!row.status ? detailedStatus : undefined;
  }

  convertStatusIntoStatusCellData(status: IChargePointStatus): StatusCellData {
    const translatedStatus = this.translate.instant('EVSESTATUS.' + status.status);
    const translatedDetailedStatus = this.translate.instant(
      'EVSEDETAILEDSTATUS.' + status.detailedStatus
    );
    return {
      type: this.convertStatusIntoStatusType(
        EStatusEnum[status.status],
        EDetailedStatusEnum[status.detailedStatus]
      ),
      text:
        status.detailedStatus !== EDetailedStatusEnum.NONE
          ? translatedDetailedStatus
          : translatedStatus,
      tooltip: translatedStatus + ' - ' + translatedDetailedStatus,
    };
  }

  convertStatusIntoStatusType(
    mainStatus: EStatusEnum,
    detailedStatus: EDetailedStatusEnum
  ): IconColor {
    switch (mainStatus) {
      case EStatusEnum.AVAILABLE:
        return IconColor.SUCCESS;
      case EStatusEnum.OCCUPIED:
        if (detailedStatus === EDetailedStatusEnum.OCCUPIED_CHARGING) {
          return IconColor.INFO;
        } else {
          return IconColor.WARNING;
        }
      case EStatusEnum.OUT_OF_SERVICE:
        if (detailedStatus === EDetailedStatusEnum.OUT_OF_SERVICE_ERROR) {
          return IconColor.ERROR;
        } else {
          return IconColor.DISABLED;
        }
      case EStatusEnum.UNKNOWN:
        if (
          detailedStatus === EDetailedStatusEnum.UNKNOWN_NEW ||
          detailedStatus === EDetailedStatusEnum.UNKNOWN_NOT_RELEVANT
        ) {
          return IconColor.DISABLED;
        } else {
          return IconColor.ERROR;
        }
    }
  }

  getAuthorizationErrorMessage(
    status: ChargingSessionDetailedStatus,
    errorMessage: string
  ): string {
    if (status === ChargingSessionDetailedStatus.AUTHORIZATION_FAILED && errorMessage) {
      const toTranslate = 'AUTH_STATUS.' + errorMessage;
      const translation = this.translate.instant(toTranslate);
      if (translation !== toTranslate) {
        return translation;
      } else {
        return errorMessage;
      }
    }
    return errorMessage;
  }
}
