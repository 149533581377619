<button
  class="title"
  (click)="toggleOpen()"
  [disabled]="!localData.items || localData.items.length <= 0"
  #origin
>
  <span
    class="body-bold-m"
    [ngClass]="{ disabled: !localData.items || localData.items.length <= 0 }"
    >{{ localData.title }}</span
  >
  <eop-icon
    *ngIf="!open"
    class="icon"
    [identifier]="'small-chevron-down-filled'"
    [color]="localData.items && localData.items.length > 0 ? IconColor.NORMAL : IconColor.DISABLED"
    [weight]="IconWeight.BOLD"
    [vaylensFont]="true"
  ></eop-icon>

  <eop-icon
    *ngIf="open"
    class="icon"
    [identifier]="'small-chevron-up-filled'"
    [color]="localData.items && localData.items.length > 0 ? IconColor.NORMAL : IconColor.DISABLED"
    [weight]="IconWeight.BOLD"
    [vaylensFont]="true"
  ></eop-icon>

  <eop-icon
    *ngIf="removable && !mandatory"
    class="icon"
    [identifier]="'trash-outlined'"
    [color]="IconColor.ERROR"
    [weight]="IconWeight.BOLD"
    [vaylensFont]="true"
    (click)="removeSpecificFilter($event)"
  ></eop-icon>
</button>
<mat-progress-bar mode="indeterminate" *ngIf="!localData.items"></mat-progress-bar>
