<div #origin class="button-holder">
  <button
    *ngIf="availableSpecificFilters"
    eopButton
    [disabled]="availableSpecificFilters.length == 0"
    [color]="ButtonColor.SECONDARY"
    [iconColor]="IconColor.HIGHLIGHTED"
    [leftIcon]="'add-outlined'"
    [attr.data-test]="'add-filter-button'"
    (click)="toggleOpen()"
  >
    {{ 'GLOBAL.ADD_FILTER' | translate }}
  </button>
</div>
