<div class="headline sub-heading-l" [ngStyle]="{ display: rowDisplay }">
  <eop-icon
    *ngIf="headlineIcon"
    class="headline-icon"
    [vaylensFont]="vaylensFont"
    [identifier]="headlineIcon"
    [weight]="IconWeight.BOLD"
    [color]="IconColor.NORMAL"
    [size]="IconSize.SMALL"
    [ngStyle]="{ float: iconFloat }"
  >
  </eop-icon>
  <ng-content></ng-content>
  <eop-icon
    *ngIf="tooltipText"
    class="tooltip-icon"
    [vaylensFont]="true"
    [color]="IconColor.NORMAL"
    [size]="IconSize.SMALL"
    [identifier]="'info-circle-filled'"
    [tooltip]="tooltipText"
  ></eop-icon>
</div>
