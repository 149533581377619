import { select } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAccountSettingValueLists } from '@auth/login';
import { ETimePeriod } from '@widgets/filter/specific/data/filter-container.model';
import { isEmpty } from 'lodash-es';
import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';
import { Observable, Subscription } from 'rxjs';

const DEFAULT_TIMEZONE = 'Europe/Berlin';
interface DateTimeRange {
  dateFrom: string;
  dateTo: string;
}

@Injectable({
  providedIn: 'root',
})
export class DateConversionService {
  @select(['backendData', 'userLogin', 'settings', 'timezone'])
  public userTimezone$: Observable<string>;
  @select(['backendData', 'userLogin', 'settings', 'datePattern'])
  public datePattern$: Observable<string>;
  @select(['backendData', 'userLogin', 'settings', 'timePattern'])
  public timePattern$: Observable<string>;
  @select(['backendData', 'settingValueList'])
  public settingValueList$: Observable<IAccountSettingValueLists>;
  @select(['backendData', 'userLogin', 'settings', 'locale'])
  public userLocale$: Observable<string>;

  private timezone = DEFAULT_TIMEZONE;
  private datePattern = '';
  private timePattern = '';
  private settingValueList: IAccountSettingValueLists;

  public timezoneSubscription: Subscription;
  public datePatternSubscription: Subscription;
  public timePatternSubscription: Subscription;
  public settingValueListSubscription: Subscription;

  constructor() {
    this.timezoneSubscription = this.userTimezone$.subscribe(value => {
      return (this.timezone = value);
    });
    this.datePatternSubscription = this.datePattern$.subscribe(value => {
      return (this.datePattern = value);
    });
    this.timePatternSubscription = this.timePattern$.subscribe(value => {
      return (this.timePattern = value);
    });
    this.settingValueListSubscription = this.settingValueList$.subscribe(value => {
      return (this.settingValueList = value);
    });
  }

  public getUserLocale$(): Observable<string> {
    return this.userLocale$;
  }

  public getUserTimeZoneAsString(): string {
    return this.timezone;
  }

  // Returns a string formatted for the backend. Example: '2011-12-30T00:00:00-08:00'
  public convertMomentToJavaIsoOffsetDateTimeString(myMoment: Moment): string {
    if (myMoment === null) return null;
    const copyMoment = myMoment.clone(); // because otherwise the incomming object would get modified
    const offset = momentTz.tz(this.timezone).format('Z');
    copyMoment.utcOffset(offset);
    return copyMoment.format('YYYY-MM-DDTHH:mm:ssZ');
  }

  public createCurrentJavaIsoOffsetDateTimeString(): string {
    return this.convertMomentToJavaIsoOffsetDateTimeString(this.createMomentInUsersTimezone());
  }

  public createMomentInUsersTimezone(): Moment {
    // add zone to dateString
    return momentTz.tz(new Date(), this.timezone);
  }

  public convertIsoOffsetDateTimeStringToMomentInUsersTimezone(dateString: string): Moment {
    // add zone to dateString
    const offset = momentTz(dateString).tz(this.timezone).format('Z');
    const myMoment = moment(dateString);
    myMoment.utcOffset(offset);
    return myMoment;
  }

  public convertIsoOffsetDateTimeStringToUserFormattedString(
    dateString: string,
    withoutSeconds = false
  ): string {
    const momentDate = this.convertIsoOffsetDateTimeStringToMomentInUsersTimezone(dateString);
    return this.convertMomentToUserSpecificReadableDateTimeFormat(momentDate, withoutSeconds);
  }

  public convertMomentToUserSpecificReadableDateTimeFormat(
    myMoment: Moment,
    withoutSeconds = false
  ): string {
    return myMoment.clone().format(this.getDateTimeFormatFromUser(true, withoutSeconds));
  }

  public getDateTimeFormatFromUser(autoUppercase = true, withoutSeconds = false): string {
    const datePatternFound = this.getDateFormatFromUser(autoUppercase);

    const timePatternFound =
      this.settingValueList.timeFormats &&
      this.settingValueList.timeFormats.find(elem => {
        return elem.key === this.timePattern;
      });

    if (datePatternFound && timePatternFound) {
      return (
        datePatternFound +
        ' ' +
        (withoutSeconds ? timePatternFound.value.replace(':ss', '') : timePatternFound.value)
      );
    } else {
      return '';
    }
  }

  public getDateFormatFromUser(autoUppercase = true): string {
    const datePatternFound =
      this.settingValueList.dateFormats &&
      this.settingValueList.dateFormats.find(elem => {
        return elem.key === this.datePattern;
      });

    if (datePatternFound) {
      return autoUppercase ? datePatternFound.value.toUpperCase() : datePatternFound.value;
    } else {
      return '';
    }
  }

  public getDateFormatFromUserWithoutYear(): string {
    const userDateFormat: string = this.getDateFormatFromUser().toUpperCase();
    const charsToTrim = ['.', '-', '/', ' '];
    const charsToTrimFromEnding = ['-', '/', ' '];
    let newDateFormat: string = userDateFormat.replace('YYYY', '');

    // Check last char.
    const lastChar = newDateFormat.charAt(newDateFormat.length - 1);
    if (charsToTrimFromEnding.indexOf(lastChar) !== -1) {
      newDateFormat = newDateFormat.substring(0, newDateFormat.length - 1);
    }
    // Check first char.
    const firstChar = newDateFormat.charAt(0);
    if (charsToTrim.indexOf(firstChar) !== -1) {
      newDateFormat = newDateFormat.substring(1, newDateFormat.length);
    }

    return newDateFormat;
  }

  public convertZuluTimeToLocalTime(timeString: string): string {
    if (timeString.length > 0 && timeString !== undefined) {
      const unzuludedTime = momentTz(timeString).tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss');
      return unzuludedTime;
    } else {
      return '';
    }
  }

  public convertToZulu(timeString: string): string {
    if (timeString.length > 0 && timeString !== undefined) {
      momentTz.tz.setDefault(this.timezone);
      const zuludedTime = momentTz(timeString)
        .tz(this.timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
      momentTz.tz.setDefault();
      return zuludedTime;
    } else {
      return '';
    }
  }

  public getTimeFormatFromUser(withoutSeconds = false, withoutAbbreviations = false): string {
    const timePatternFound =
      this.settingValueList.timeFormats &&
      this.settingValueList.timeFormats.find(elem => {
        return elem.key === this.timePattern;
      });

    let timeFormat = '';
    if (timePatternFound) {
      timeFormat = timePatternFound.value;
      if (withoutSeconds) {
        timeFormat = timeFormat.replace(':ss', '');
      }
      if (withoutAbbreviations) {
        timeFormat = timeFormat.replace(' a', '');
      }
    }

    return timeFormat;
  }

  public getFormattedDurationFromISO8601TimeInterval(duration: string): string {
    const momentDuration = moment.duration(duration);
    const hours = Math.floor(momentDuration.as('h'));
    return (
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      moment.utc(momentDuration.asMilliseconds()).format('mm:ss')
    );
  }

  public timezoneOffsetInSeconds(): number {
    if (isEmpty(this.timezone)) {
      this.timezone = DEFAULT_TIMEZONE;
    }
    return -momentTz.tz.zone(this.timezone).utcOffset(new Date().getTime()) * 60;
  }

  /**
   * Converts e.g. 12:20:03 (UTC/GMT) to 02:20:03 pm (Europe/Berlin)
   */
  public utcTimeToUsersTime(time: string): string {
    return this.addUtcOffsetToTime(time).format(this.getTimeFormatFromUser());
  }

  /**
   * Converts e.g. 12:20:03 (UTC/GMT) to 14:20:03 (Europe/Berlin)
   */
  public utcTimeToUsersTimeIn24HourFormat(time: string): string {
    return this.addUtcOffsetToTime(time).format('HH:mm:ss');
  }

  /**
   * Converts e.g. 14:20:03 (Europe/Berlin) to 12:20:03 (UTC/GMT)
   */
  public usersTimeIn24HoursFormatToUtcTime(time: string) {
    return this.addUtcOffsetToTime(time, true).format('HH:mm:ss');
  }

  private addUtcOffsetToTime(time: string, subtract = false): Moment {
    const offsetToUtc = this.timezoneOffsetInSeconds() / (60 * 60);
    const timeAsMoment = moment('1970-01-01T' + time);
    return timeAsMoment.add(subtract ? -offsetToUtc : offsetToUtc, 'hour');
  }

  public calculateDatesDependingOnPeriod(
    period: ETimePeriod,
    preselectDateFrom?: string,
    preselectDateTo?: string
  ): DateTimeRange {
    let start: Moment;
    let end: Moment;
    switch (period) {
      case ETimePeriod.INDIVIDUAL_TIME_PERIOD:
        start = moment(preselectDateFrom).startOf('day');
        end = moment(preselectDateTo).endOf('day');
        break;
      case ETimePeriod.LAST_HOUR:
        start = moment().subtract(1, 'hour');
        end = null;
        break;
      case ETimePeriod.LAST_4_HOURS:
        start = moment().subtract(4, 'hours');
        end = null;
        break;
      case ETimePeriod.LAST_12_HOURS:
        start = moment().subtract(12, 'hours');
        end = null;
        break;
      case ETimePeriod.LAST_24_HOURS:
        start = moment().subtract(1, 'day');
        end = null;
        break;
      case ETimePeriod.LAST_7_DAYS:
        start = moment().startOf('day').subtract(7, 'day');
        end = moment().endOf('day');
        break;
      case ETimePeriod.LAST_14_DAYS:
        start = moment().startOf('day').subtract(14, 'day');
        end = moment().endOf('day');
        break;
      case ETimePeriod.LAST_30_DAYS:
        start = moment().startOf('day').subtract(30, 'day');
        end = moment().endOf('day');
        break;
      case ETimePeriod.LAST_60_DAYS:
        start = moment().startOf('day').subtract(60, 'day');
        end = moment().endOf('day');
        break;
      case ETimePeriod.LAST_90_DAYS:
        start = moment().startOf('day').subtract(90, 'day');
        end = moment().endOf('day');
        break;
      case ETimePeriod.NEXT_7_DAYS:
        start = moment().startOf('day');
        end = moment().endOf('day').add(7, 'day');
        break;
      case ETimePeriod.NEXT_14_DAYS:
        start = moment().startOf('day');
        end = moment().endOf('day').add(14, 'day');
        break;
    }

    return {
      dateFrom: this.convertMomentToJavaIsoOffsetDateTimeString(start),
      dateTo: this.convertMomentToJavaIsoOffsetDateTimeString(end),
    };
  }
}
