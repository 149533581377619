import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import {
  ChipLiteColor,
  ChipLiteSize,
} from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';
import { HighlightedColor } from '@widgets/innogy-data-table/data/data-table-row.data';

export enum CellType {
  LINK = 'LINK',
  CHIP = 'CHIP',
  BUTTON = 'BUTTON',
  TEXT = 'TEXT',
}

export interface ChipConfig {
  text: string;
  chipColor: ChipLiteColor;
  chipSize: ChipLiteSize;
}

export interface LinkConfig {
  text: string;
  url: string;
}

export interface ButtonConfig {
  text: string;
  icon: string;
  url: string;
  disabled: boolean;
}

export interface TextConfig {
  text: string;
}

@Component({
  selector: 'dynamic-cell',
  templateUrl: 'dynamic-cell.component.html',
  styleUrls: ['dynamic-cell.component.scss'],
})
export class DynamicCellComponent {
  readonly CellType = CellType;
  readonly ChipLiteSize = ChipLiteSize;
  readonly ChipLiteColor = ChipLiteColor;
  readonly HighlightedColor = HighlightedColor;

  @Input()
  cellType: CellType;
  @Input()
  chipConfig: ChipConfig;
  @Input()
  linkConfig: LinkConfig;
  @Input()
  buttonConfig: ButtonConfig;
  @Input()
  textConfig: TextConfig;

  @Output()
  dynamicCellButtonClicked = new EventEmitter<void>();
  protected readonly ButtonColor = ButtonColor;
}
