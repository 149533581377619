<div class="filter-wrapper">
  <div class="filter-header" [ngClass]="{ collapsed: !expandedFilter }">
    <div class="icon-with-label">
      <eop-icon
        [identifier]="'eoperate-ic-filter'"
        [color]="IconColor.NORMAL"
        [weight]="IconWeight.BOLD"
        [size]="IconSize.SMALL"
        [vaylensFont]="true"
      ></eop-icon>
      <label class="filter-label">{{ 'GLOBAL.FILTER' | translate }}</label>
    </div>

    <eop-icon
      class="icon-action"
      (click)="toggleFilter()"
      [identifier]="expandedFilter ? 'chevron-up-outlined' : 'chevron-down-outlined'"
      [color]="IconColor.NORMAL"
      [size]="IconSize.NORMAL"
      [vaylensFont]="true"
    ></eop-icon>
  </div>
  <div class="filter-content" [class]="{ hide: !expandedFilter }">
    <eop-address-with-suggest
      *ngIf="addressFilterV3 && !hideAddressFilter"
      #addressWithSuggestFilter
      [permission]="addressPermission"
      [partnerId]="partnerId"
      [locationId]="locationId"
      [onlyIbetStations]="onlyIbetStations"
      [countryIso3]="countryIso3"
      (addressChange)="onAddressChangeV3($event)"
    >
    </eop-address-with-suggest>

    <eop-address-new-filter
      *ngIf="!addressFilterV3 && !hideAddressFilter"
      #addressFilter
      [countries]="countries"
      [cityZips]="cityZips"
      [streets]="streets"
      (requireNewCountries)="onRequireNewCountries()"
      (requireNewCityZips)="onRequireNewCityZips($event)"
      (requireNewStreets)="onRequireNewStreets($event)"
      (addressChange)="onAddressChange($event)"
    ></eop-address-new-filter>

    <div class="filter-items">
      <eop-specific-filter
        [specificFilterConfig]="specificFilterConfig"
        [componentId]="componentId"
        [componentsData]="componentsData"
        [removable]="!!availableSpecificFilters"
        (filter)="onSpecificFilter($event)"
        (addInitPreselect)="onAddInitPreselect($event)"
        (addPreselect)="onAddPreselect($event)"
        (removePreselect)="onRemovePreselect($event)"
        *ngFor="
          let specificFilterConfig of displayedSpecificFilters;
          let componentId = index;
          trackBy: trackByFn
        "
      ></eop-specific-filter>
      <eop-add-filter-button
        [availableSpecificFilters]="availableSpecificFilters"
        (addSpecificFilter)="addSpecificFilter($event)"
      ></eop-add-filter-button>
    </div>

    <div class="filter-footer" *ngIf="chipsData.length > 0">
      <div class="chips">
        <eop-innogy-chips-lite
          *ngFor="let chip of chipsData; let componentId = index; trackBy: trackByFn"
          [text]="chip.chipName"
          [chipColor]="ChipLiteColor.DEFAULT"
          [chipSize]="ChipLiteSize.BIG"
          [rightIcon]="'close-outlined'"
          [maxWidth]="'500px'"
          (rightIconClicked)="onChipRemoved(chip)"
          class="chip"
        ></eop-innogy-chips-lite>
      </div>
      <button
        eopButton
        (click)="removeAllFilterAndTriggerSearch()"
        [color]="ButtonColor.ERROR"
        leftIcon="close-circle-outlined"
        style="margin-top: 12px"
      >
        {{ 'AG_GRID.RESET_FILTERS' | translate }}
      </button>
    </div>
  </div>
</div>
