import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EopButtonModule } from '@widgets/eop-button/eop-button.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { AddressCityZipElementComponent } from '@widgets/filter/address-new/address-city-zip-element/address-city-zip-element.component';
import { AddressElementComponent } from '@widgets/filter/address-new/address-element/address-element.component';
import { AddressNewFilterComponent } from '@widgets/filter/address-new/address.component';
import { SortPipe } from '@widgets/filter/address-new/pipes/sort.pipe';
import { TranslatedSortPipe } from '@widgets/filter/address-new/pipes/translatedSort.pipe';
import { InnogyChipsLiteModule } from '@widgets/innogy-chips-lite/innogy-chips-lite.module';
import { InnogyChipsModule } from '@widgets/innogy-chips/innogy-chips.module';
import { AddressWithSuggestComponent } from './address-with-suggest/address-with-suggest.component';
import { CitiesAndZipsComponent } from './address-with-suggest/cities-and-zips/cities-and-zips.component';
import { CountriesComponent } from './address-with-suggest/countries/countries.component';
import { StreetsComponent } from './address-with-suggest/streets/streets.component';
import { FilterComponent } from './filter.component';
import { FilterInputComponent } from './specific/components/filter-input.component';
import { MultiselectComponent } from './specific/components/multiselect/multiselect.component';
import { SearchInputComponent } from './specific/components/search-input/search-input.component';
import { SingleDateTimeSelectComponent } from './specific/components/single-date-time-select/single-date-time-select.component';
import { SingleselectComponent } from './specific/components/singleselect/singleselect.component';
import { StatusCascadingSelectComponent } from './specific/components/status-cascading-select/status-cascading-select.component';
import { StatusSingleCascadingSelectComponent } from './specific/components/status-single-cascading-select/status-single-cascading-select.component';
import { PopoverComponent } from './specific/containers/popover.component';
import { FilterContainerComponent } from './specific/filter-container.component';
import { FilterBackendService } from './specific/services/filter-backend.service';
import { OverlayService } from './specific/services/overlay.service';
import { AddFilterButtonComponent } from './specific/components/add-filter-button/add-filter-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    EopIconModule,
    InnogyChipsModule,
    TranslateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ScrollingModule,
    MatAutocompleteModule,
    EopButtonModule,
    InnogyChipsLiteModule,
  ],
  declarations: [
    FilterComponent,
    AddressNewFilterComponent,
    FilterContainerComponent,
    PopoverComponent,
    MultiselectComponent,
    SingleselectComponent,
    StatusCascadingSelectComponent,
    StatusSingleCascadingSelectComponent,
    SingleDateTimeSelectComponent,
    SearchInputComponent,
    FilterInputComponent,
    AddressElementComponent,
    AddressCityZipElementComponent,
    TranslatedSortPipe,
    SortPipe,
    TranslatedSortPipe,
    SortPipe,
    AddressWithSuggestComponent,
    CountriesComponent,
    CitiesAndZipsComponent,
    StreetsComponent,
    AddFilterButtonComponent,
  ],
  exports: [
    FilterComponent,
    AddressNewFilterComponent,
    FilterContainerComponent,
    MultiselectComponent,
    SingleselectComponent,
    StatusCascadingSelectComponent,
    SingleDateTimeSelectComponent,
    SearchInputComponent,
    AddressElementComponent,
    AddressCityZipElementComponent,
  ],
  providers: [FilterBackendService, OverlayService, DatePipe],
})
export class FilterModule {}
