<div [ngSwitch]="cellType">
  <div *ngSwitchCase="CellType.LINK">
    <link-cell
      class="pointy"
      [text]="linkConfig.text"
      [eventUrl]="linkConfig.url"
      [shouldTextCrop]="true"
    ></link-cell>
  </div>

  <div *ngSwitchCase="CellType.BUTTON">
    <button
      eopButton
      (click)="dynamicCellButtonClicked.emit(); $event.stopPropagation()"
      [color]="ButtonColor.OUTLINE_GREY"
      [compact]="true"
      [leftIcon]="buttonConfig?.icon ? buttonConfig.icon : undefined"
      [disabled]="buttonConfig?.disabled"
    >
      {{ buttonConfig.text }}
    </button>
  </div>

  <div *ngSwitchCase="CellType.CHIP">
    <eop-innogy-chips-lite
      [chipColor]="chipConfig.chipColor"
      [chipSize]="chipConfig.chipSize"
      [text]="chipConfig.text"
    ></eop-innogy-chips-lite>
  </div>

  <div *ngSwitchCase="CellType.TEXT">
    {{ textConfig.text }}
  </div>
</div>
