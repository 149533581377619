import { Platform } from '@angular/cdk/platform';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { PipesModule } from '@shared/pipes/pipe.module';
import { DateConversionService } from '@shared/services/util';
import { BackButtonComponent } from '@widgets/back-button/back-button.component';
import { GroupListComponent } from '@widgets/eop-group';
import { EopIconWithTooltipModule } from '@widgets/eop-icon-with-tooltip/eop-icon-with-tooltip.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { EopModalComponent } from '@widgets/eop-modal';
import { EopNavigationListComponent } from '@widgets/eop-navigation-list/eop-navigation-list.component';
import { GridListComponent } from '@widgets/grid-list/grid-list.component';
import { GroupComponent } from '@widgets/group/group.component';
import { GroupsComponent } from '@widgets/groups/groups.component';
import { InnogyChipsLiteModule } from '@widgets/innogy-chips-lite/innogy-chips-lite.module';
import { PageHeadlineComponent } from '@widgets/page-headline';
import { PageHeadlineModule } from '@widgets/page-headline/page-headline.module';
import { SnackbarComponent } from '@widgets/snackbar/snackbar.component';
import { SpinnerModule } from '@widgets/spinner/spinner.module';
import { TabsModule } from '@widgets/tabs/tabs.module';
import { TimeFormComponent } from '@widgets/time-form';
import { ToggleButtonComponent } from '@widgets/toggle-button/toggle-button.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxWeekdayPickerModule } from 'ngx-weekday-picker';
import { AgGridFilterHeaderComponent } from './ag-grid-filter-header/ag-grid-filter-header.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BoxModule } from './box/box.module';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { LineChartModule } from './charts/line-chart/line-chart.module';
import { NormalizedStackedBarChartModule } from './charts/normalized-stacked-bar-chart/normalized-stacked-bar-chart.module';
import { PieChartModule } from './charts/pie-chart/pie-chart.module';
import {
  ConfirmDialogComponent,
  ConfirmDialogWithTitleIconText,
  DeleteDialogComponent,
  InfoDialogComponent,
} from './dialogs';
import { AutoNumericDirective } from './directives/auto-numeric/auto-numeric.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { InputMaskDirective } from './directives/input-mask/input-mask.directive';
import { EopButtonModule } from './eop-button/eop-button.module';
import { ImagePlaceholderModule } from './eop-image-placeholder/eop-image-placeholder.module';
import { PasswordInputComponent } from './eop-password-field/password-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilterModule } from './filter/filter.module';
import { DateFormatAdapter } from './filter/specific/services/date-format-adapter';
import { FormInputItemModule } from './form-input-item/form-input-item.module';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InnogyChipsModule } from './innogy-chips/innogy-chips.module';
import { InnogyDataTableModule } from './innogy-data-table/innogy-data-table.module';
import { InputEditModeModule } from './input-edit-mode/input-edit-mode.module';
import { LoadingResultsComponent } from './loading-results/loading-results.component';
import { MapModule } from './map/map.module';
import { PaginationModule } from './pagination/pagination.module';
import { ResultBannerComponent } from './result-banner/result-banner.component';
import { SaveButtonComponent } from './save-button';
import { SectionHeadlineComponent } from './section-headline/section-headline.component';
import { SnackbarModule } from './snackbar/snackbar.module';
import { StatusSystemIconModule } from './status-system-icon/status-system-icon.module';
import { TextBoxComponent } from './text-box/text-box.component';
import { ToggleBoxComponent } from './toggle-box/toggle-box.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { ValidationMessageModule } from './validation-message/validation-message.modul';
import { WeekDayPickerComponent } from './week-day-picker/week-day-picker.component';
import { WizardFirstPageComponent } from './wizard-first-page/wizard-first-page.component';

@NgModule({
  declarations: [
    GroupComponent,
    GroupsComponent,
    GroupListComponent,
    InputMaskDirective,
    SaveButtonComponent,
    ClickOutsideDirective,
    ConfirmDialogComponent,
    DeleteDialogComponent,
    InfoDialogComponent,
    PasswordInputComponent,
    SnackbarComponent,
    InfoBoxComponent,
    InfoBoxComponent,
    ToggleButtonComponent,
    ToggleSwitchComponent,
    EopModalComponent,
    ConfirmDialogWithTitleIconText,
    AutoNumericDirective,
    TimeFormComponent,
    BackButtonComponent,
    EopNavigationListComponent,
    SectionHeadlineComponent,
    GridListComponent,
    LoadingResultsComponent,
    FileUploadComponent,
    ButtonToggleGroupComponent,
    AgGridFilterHeaderComponent,
    TextBoxComponent,
    WizardFirstPageComponent,
    ToggleBoxComponent,
    AvatarComponent,
    WeekDayPickerComponent,
    ResultBannerComponent,
  ],
  exports: [
    GroupComponent,
    GroupsComponent,
    GroupListComponent,
    ValidationMessageModule,
    InputMaskDirective,
    SaveButtonComponent,
    ClickOutsideDirective,
    LineChartModule,
    NormalizedStackedBarChartModule,
    PieChartModule,
    ConfirmDialogComponent,
    DeleteDialogComponent,
    InfoDialogComponent,
    InnogyDataTableModule,
    InnogyChipsModule,
    PasswordInputComponent,
    PageHeadlineComponent,
    InfoBoxComponent,
    FilterModule,
    BoxModule,
    TabsModule,
    ToggleButtonComponent,
    ToggleSwitchComponent,
    EopIconModule,
    MatDialogModule,
    EopModalComponent,
    ConfirmDialogWithTitleIconText,
    AutoNumericDirective,
    ImagePlaceholderModule,
    FormInputItemModule,
    TimeFormComponent,
    BackButtonComponent,
    SpinnerModule,
    EopIconWithTooltipModule,
    InnogyChipsLiteModule,
    EopNavigationListComponent,
    SectionHeadlineComponent,
    GridListComponent,
    StatusSystemIconModule,
    SnackbarModule,
    LoadingResultsComponent,
    FileUploadComponent,
    ButtonToggleGroupComponent,
    AgGridFilterHeaderComponent,
    TextBoxComponent,
    WizardFirstPageComponent,
    ToggleBoxComponent,
    InputEditModeModule,
    AvatarComponent,
    WeekDayPickerComponent,
    ResultBannerComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: DateFormatAdapter,
      deps: [DateConversionService, DatePipe, Platform],
    },
    DecimalPipe,
  ],
  imports: [
    CommonModule,
    InlineSVGModule.forRoot({ baseUrl: '/' }),
    FormsModule,
    TranslateModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    InnogyDataTableModule,
    InnogyChipsModule,
    LineChartModule,
    NormalizedStackedBarChartModule,
    PieChartModule,
    FilterModule,
    BoxModule,
    MatSelectModule,
    MatAutocompleteModule,
    TabsModule,
    EopIconModule,
    ImagePlaceholderModule,
    FormInputItemModule,
    SpinnerModule,
    RouterModule,
    EopIconWithTooltipModule,
    InnogyChipsLiteModule,
    StatusSystemIconModule,
    PageHeadlineModule,
    PaginationModule,
    PipesModule,
    SharedDirectivesModule,
    MatButtonToggleModule,
    MapModule,
    InputEditModeModule,
    MatRippleModule,
    EopButtonModule,
    NgxWeekdayPickerModule,
  ],
})
export class WidgetModule {}
